<template>
	<navBar/>
    <Loader v-if="loading"/>
	<div class="main">
        <div class="header">
            <div class="gradient">
                <div class="top border-radius-bottom">
                </div>
            </div>
            <div class="bottom">
                <div class="px-3 pt-3">
                    <h5 class="title fw-bolder mb-0">KIO88</h5>
                    <div class="members">
                        <span >Group: </span><strong>{{ members }} members</strong>
                    </div>
                    <div class="members-profile mt-2 justify-content-between">
                        <div class="member-images">
                            <img :src="getImagePath(i)" v-for="i in 10" :key="i" />
                        </div>
                        <div class="member-share">
                            <!-- <button style="background-color: #0866FF;" class="border-0 p-2 rounded-2 mx-1 text-white" @click="invite_modal = true">
                                <i class="bx bx-plus px-1 fw-bold fs-6"></i>
                                Invite
                            </button> -->
                            <button style="background-color: #0866FF;" class="border-0 p-2 rounded-2 mx-1 text-white" @click="share_modal = true">
                                <i class="bx bx-share px-1 fw-bold fs-6"></i>
                                Share
                            </button>
                        </div>
                    </div>
                    <hr>
                    <div class="bottom-nav justify-content-between pt-2">
                        <div class="discussions d-flex">
                            <!-- <div @click="tabs = 'discussions'" :class="tabs == 'discussions' ? 'selected-route' : 'cursor-pointer'">
                                <h6 class="mx-3 pb-1">Discussions</h6>
                            </div>
                            <div @click="tabs = 'announcement'" :class="tabs == 'announcement' ? 'selected-route' : 'cursor-pointer'">
                                <h6 class="mx-3 pb-1">Announcement</h6>
                            </div>
                            <div @click="tabs = 'media'" :class="tabs == 'media' ? 'selected-route' : 'cursor-pointer'">
                                <h6 class="mx-3 pb-1">Media</h6>
                            </div> -->
                            <div @click="tabs = 'available'" :class="tabs == 'available' ? 'selected-route' : 'cursor-pointer'">
                                <h6 class="mx-2 pb-1">New Arrivals</h6>
                            </div>
                            <div @click="tabs = 'rules'" :class="tabs == 'rules' ? 'selected-route' : 'cursor-pointer'">
                                <h6 class="mx-2 pb-1">Rules</h6>
                            </div>
                            <div @click="tabs = 'fortune'" :class="tabs == 'fortune' ? 'selected-route' : 'cursor-pointer'">
                                <h6 class="mx-2 pb-1">Fortune Teller</h6>
                            </div>
                            <div @click="tabs = 'charity'" :class="tabs == 'charity' ? 'selected-route' : 'cursor-pointer'">
                                <h6 class="mx-2 pb-1">Charity</h6>
                            </div>
                            <div @click="tabs = 'about'" :class="tabs == 'about' ? 'selected-route' : 'cursor-pointer'" class="nav-about">
                                <h6 class="mx-2 pb-1">About</h6>
                            </div>
                        </div>
                        <div class="search-dots pb-3">
                            <!-- <button class="border-0 p-2 rounded-2" >
                                <i class="bx bx-search px-1 fw-bold fs-5" @click="search_modal = true"></i>
                            </button> -->
                            <!-- <b-dropdown
                                right
                                menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
                                toggle-class="header-item noti-icon"
                                variant="black"
                            >
                                <template v-slot:button-content>
                                    <button class="border-0 p-2 rounded-2">
                                        <i class="bx bx-search px-1 fw-bold fs-5" @click="search_modal = true"></i>
                                    </button>
                                </template>
                            </b-dropdown> -->
                            <b-dropdown
                                right
                                menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
                                toggle-class="header-item noti-icon"
                                variant="black"
                            >
                                <template v-slot:button-content>
                                    <button class="border-0 p-2 rounded-2">
                                        <i class="bx bx-dots-horizontal-rounded px-1 fw-bold fs-5"></i>
                                    </button>
                                </template>
                                <!-- item-->
                                <div class="p-2">
                                    <button class="dropdown-item text-dark p-2 fw-bold">
                                        <i
                                            class="bx bx-share font-size-16 align-middle me-1"
                                        ></i>
                                        Share
                                    </button>
                                    <button class="dropdown-item text-dark p-2 fw-bold">
                                        <i
                                            class="bx bx-pin font-size-16 align-middle me-1"
                                        ></i>
                                        Pin posts
                                    </button>
                                </div>
                            </b-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="body" v-if="tabs == 'available'">
            <div class="left p-2">
                <div class="post-box p-3 mt-2 border-radius box-shadow" v-if="profile.role == 'seller'"  @click="post_modal = true">
                    <div class="d-flex">
                        <div class="">
                            <img v-if="!this.profile.image" 
                                height="40" width="40"
                                class="raidus-100"
                                src="@/assets/images/profiles/default.png" >
                            <img
                                v-if="this.profile.image"
                                :src="`${image_api}/${this.profile.image}`"
                                height="40" width="40"
                                class="raidus-100"
                            />
                        </div>
                        <div class="post">
                            <button class="post-input">Write something...</button>
                        </div>
                    </div>
                    <hr style="color: #999999;" class="mt-3 mb-2">
                    <div class="col-12 upload">
                        <div class="d-flex" style="justify-content: center;align-items: center; ">
                            <div class="d-flex mx-3">
                                <i class="bx bx-video" style="color: #FC5677;"></i>
                                <h6 class="mt-2 mx-2">Upload/Video</h6>
                            </div>
                            <div class="d-flex mx-3">
                                <i class="bx bx-image" style="color: green;"></i>
                                <h6 class="mt-2 mx-2">Upload/Image</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-2 bg-white border-radius box-shadow">
                    <h6 class="p-3">Featured</h6>
                </div>
                <div :id="data._id" class="mt-2 bg-white border-radius box-shadow" v-for="data,i in data.list" :key="i">
                    <div class="d-flex p-3" style="position:relative">
                        <div class="users" >
                            <img v-if="!data.owner_dp" 
                                height="40" width="40"
                                class="raidus-100"
                                @click="gotoProfile(data)"
                                src="@/assets/images/profiles/default.png" >
                            <img
                                v-if="data.owner_dp"
                                :src="`${image_api}/${data.owner_dp}`"
                                height="40" width="40"
                                @click="gotoProfile(data)"
                                class="raidus-100"
                            />
                            <b-dropdown
                                right
                                menu-class="dropdown-menu-sm p-0 dropdown-menu-end"
                                toggle-class="header-item noti-icon"
                                variant="black"
                                style="position: absolute; right:10px;top:0;" 
                            >
                                <template v-slot:button-content>
                                    <i class="bx bx-dots-horizontal-rounded px-1 fw-bold fs-5"></i>
                                </template>
                                <!-- item-->
                                <div class="p-2">
                                    <button class="dropdown-item text-dark p-2 fw-bold" @click="reportUser(data)">
                                        <i
                                            class="bx bxs-megaphone font-size-16 align-middle me-1"
                                        ></i>
                                        Report
                                    </button>
                                    <button v-if="profile.username == data.owner" class="dropdown-item text-dark p-2 fw-bold" @click="getPostData(data)">
                                        <i
                                            class="bx bxs-pencil font-size-16 align-middle me-1"
                                        ></i>
                                        Update
                                    </button>
                                    <button v-if="profile.username == data.owner" class="dropdown-item text-danger p-2 fw-bold" @click="deletePost(data)">
                                        <i
                                            class="bx bxs-trash font-size-16 align-middle me-1"
                                        ></i>
                                        Delete
                                    </button>
                                </div>
                            </b-dropdown>
                        </div>
                        <div>
                            <span class="mx-3" style="color: #050505" @click="gotoProfile(data)">{{data.owner}}</span>
                            <p class="mx-3" style=" color:#999999; font-size: 10px;">{{`${timeOnly(data.created_at)} ~ ${dateOnly(data.created_at)}`}}</p>
                            <h6 class="mx-3" v-if="data.text != 'null'" v-html="formatText(data.text)"></h6>
                            <!-- <div v-if="data.media">
                            .replace(/,/g,"<br>")
                                <div class="image-container" v-if="parsedMedia(data.media).length > 0" >
                                    <img @click="postView(image)" v-for="(image, index) in parsedMedia(data.media)" :src="getMediaUrl(image)" :key="index" :alt="`Image ${index + 1}`" class="media-image cursor-pointer" height="250" width="250"/>
                                </div>
                            </div> -->
                            <div v-if="data.media">
                                <div class="image-container" v-if="parsedMedia(data.media).length > 0" >
                                    <div v-for="(image, index) in parsedMedia(data.media).slice(0,4)" :key="index" class="media-item"> 
                                        <img @click="viewImages(data)" v-if="image.includes('.jpg') || image.includes('.jpeg') || image.includes('.png') ||image.includes('.gif') || image.includes('.tiff') || image.includes('.raw') || image.includes('.heic') || image.includes('.jfif') " :src="getMediaUrl(image)" class="cursor-pointer" height="250" width="250"/>
                                        <video
                                            v-if="image.includes('.mp4')|| image.includes('.webm') || image.includes('.ogg') || image.includes('.mov') || image.includes('.avi') ||image.includes('.wmv') || image.includes('.webm')"
                                            :src="getMediaUrl(image)"
                                            controls
                                            poster='@/assets/images/video.png'
                                            height="250"
                                            width="250"
                                        ></video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="justify-content-between px-5 pb-2" style="display:flex; align-items:center;">
                        <div class="text-start" v-if="data.reactions">
                            <!-- <span style="color: #999999" v-if="data.reactions.length > 0">
                                {{`${data.reactions.length} ${data.reactions.length > 1 ? 'reactions': 'react'}`}}
                            </span> -->
                            <div class="text-start" v-if="data.reactions_summary">
                                <span style="color: #999999">
                                    {{`${data.reactions_summary.like > 0 ? data.reactions_summary.like + ' 👍 ': ''}`}}
                                </span>
                                <span style="color: #999999">
                                    {{`${data.reactions_summary.haha > 0 ? data.reactions_summary.haha + ' 😆 ': ''}`}}
                                </span>
                                <span style="color: #999999">
                                    {{`${data.reactions_summary.heart > 0 ? data.reactions_summary.heart + ' ❤️ ': ''}`}}
                                </span>
                                <span style="color: #999999">
                                    {{`${data.reactions_summary.wow > 0 ? data.reactions_summary.wow + ' 😮 ': ''}`}}
                                </span>
                            </div>
                        </div>
                        <div class="text-start" v-if="data.comments">
                            <span style="color: #999999" v-if="data.comments.length > 0">
                                {{`${data.comments.length} ${data.comments.length > 1 ? 'comments': 'comment'}`}}
                            </span>
                        </div>
                    </div>
                    <div class="like-comment text-center d-flex px-2">
                        <div>
                            <!-- <i class="bx bx-like p-0 mb-0 fs-5 mx-2"></i><span class="fs-5">Like</span> -->
                             <div class="reaction-button-container" @click="toggleReactions(i,data)">
                                <i class="reaction-button post-buttons bx bx-like p-0 mb-0 fs-5 mx-2"></i><span class="fs-5 post-buttons">Like</span>
                                <div v-if="reactionsVisible == i && toggle" class="reactions">
                                    <div class="reaction" v-for="reaction in reactions" :key="reaction" @click="postReaction(data,$event)">
                                        {{ reaction }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <i class="bx bx-message fs-5 post-buttons mx-2"></i><span class="fs-5 post-buttons">Comment</span>
                        </div>
                        <div  @click="sendTo(data)" :hidden="profile.username == data.owner">
                            <i class="bx bx-send fs-5 post-buttons mx-2"></i><span class="fs-5 post-buttons">Send</span>
                        </div>
                    </div>
                    <div>
                        <div v-if="data.comments.length">
                            <div  class="comment-section d-flex px-3 pt-3"  v-for="(data, index) in data.comments" :key="index">
                                <img v-if="!data.owner_dp" @click="gotoProfile(data)" class="profile-picture" src="@/assets/images/profiles/default.png" >
                                <img
                                    v-if="data.owner_dp"
                                    @click="gotoProfile(data)"
                                    :src="`${image_api}/${data.owner_dp}`"
                                    height="40" width="40"
                                    class="raidus-100"
                                />
                                <div class="comment-details mx-3">
                                
                                    <div class="bubble" style="position: relative;">
                                        <h6 class="fw-bold mb-0" @click="gotoProfile(data)">{{data.owner}}</h6>
                                        <h6 class="text-muted comment-text" v-html="formatText(data.text)"></h6>
                                        <b-dropdown
                                            right
                                            menu-class="dropdown-menu-sm p-0 dropdown-menu-end"
                                            toggle-class="header-item noti-icon"
                                            variant="black"
                                            style="position: absolute; right:5px;top:-10px;" 
                                        >
                                            <template v-slot:button-content>
                                                <i class="bx bx-dots-horizontal-rounded px-1 fw-bold fs-5"></i>
                                            </template>
                                            <!-- item-->
                                            <div class="p-2">
                                                <button class="dropdown-item text-dark p-2 fw-bold" @click="reportUser(data)">
                                                    <i
                                                        class="bx bxs-megaphone font-size-16 align-middle me-1"
                                                    ></i>
                                                    Report
                                                </button>
                                                <button v-if="profile.username == data.owner" class="dropdown-item text-danger p-2 fw-bold" @click="commentDelete(data)">
                                                    <i
                                                        class="bx bxs-trash font-size-16 align-middle me-1"
                                                    ></i>
                                                    Delete
                                                </button>
                                            </div>
                                        </b-dropdown>
                                    </div>
                                    <div style="color: #999999;font-size: 12px;">
                                        <span class="px-3">{{`${timeOnly(data.created_at)} ~ ${dateOnly(data.created_at)}`}}</span>
                                        <!-- <span class="px-3"><strong>Like</strong></span> -->
                                        <span class="px-3" @click="getIndex(index)" style="cursor:pointer;"><strong>Reply</strong></span>
                                        <div v-if="data.sub_comments.length > 0" >
                                            <div  class="comment-section  d-flex pt-3"  v-for="(data, i) in data.sub_comments" :key="i" >
                                                <img v-if="!data.owner_dp" @click="gotoProfile(data)" class="profile-picture mt-2" src="@/assets/images/profiles/default.png" >
                                                <img
                                                    v-if="data.owner_dp"
                                                    @click="gotoProfile(data)"
                                                    :src="`${image_api}/${data.owner_dp}`"
                                                    height="40" width="40"
                                                    class="raidus-100"
                                                />
                                                <div class="comment-details mx-3">
                                                    <div class="bubble">
                                                        <h6 class="fw-bold mb-0" @click="gotoProfile(data)">{{data.owner}}</h6>
                                                        <span class="text-dark mb-0 comment-text" v-html="formatText(data.text)"></span>
                                                    </div>
                                                    <div class="reply-text" style="color: #999999;font-size: 12px;">
                                                        <span class="px-3">{{`${timeOnly(data.created_at)} ~ ${dateOnly(data.created_at)}`}}</span>
                                                        <!-- <span class="px-3"><strong>Like</strong></span> -->
                                                        <span class="px-3" @click="getReplyData(data,index)" style="cursor:pointer;"><strong>Reply</strong></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="comment d-flex p-3" v-if="this.reply_index == index && reply_toggle == true">
                                            <img v-if="!this.profile.image" 
                                                height="32" width="32"
                                                class="raidus-100"
                                                src="@/assets/images/profiles/default.png" >
                                            <img
                                                v-if="this.profile.image"
                                                :src="`${image_api}/${this.profile.image}`"
                                                height="32" width="32"
                                                class="raidus-100"
                                            />
                                            <input class="input-padding" maxlength="500" type="text" :ref="`replyInput${index}`" placeholder="Comment.." @keyup.enter="replySubmit($event,data,index)" :value="this.reply_owner"/>
                                            <i class="bx bx-send send-icon" @click="replyComment(data, index)"></i>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="comment d-flex p-3">
                        <img v-if="!this.profile.image" 
                            height="32" width="32"
                            class="raidus-100"
                            src="@/assets/images/profiles/default.png" >
                        <img
                            v-if="this.profile.image"
                            :src="`${image_api}/${this.profile.image}`"
                            height="32" width="32"
                            class="raidus-100"
                        />
                        <input :ref="`commentInput${i}`" maxlength="500" type="text"  placeholder="Comment.." @keyup.enter="commentSubmit($event,data,i)"/>
                        <i class="bx bx-send send-icon" @click="submitComment(data, i)"></i>
                    </div>
                </div>
            </div>
            <div class="right p-2">
                <div class="about mt-2 border-radius box-shadow">
                    <div class="p-3">
                        <!-- <h6 class="fw-bold" style="color: #050505">About us</h6> -->
                        <span class="fw-bolder">About us</span>
                        <p class="p-1 px-2" style="font-size: 14px;">This portal is design for all the sellers who wants to do business in secured pace. <br><br> This portal is only for Inspired Items.<br><br>For Long time purposes of Business<br><br>This portal will also affiliated with charities - pay in any amount/anytime<br><br>Our Portal is also affiliated with a very strong healer/Fortune Teller/ Fengsui practitioner, anyone willing to talk or ask for a guidance , please don’t hesitate to contact our admin.</p>
                        <div class="d-flex">
                            <i class="bx bx-money fs-5"></i>
                            <span class="mx-3" style="margin-top: -2px;">Selling Products</span><br><br>
                        </div>
                        <!-- <div class="d-flex">
                            <i class="bx bx-dollar fs-5"></i>
                            <span class="mx-3" style="margin-top: -2px;">Buying</span><br><br>
                        </div> -->
                        <div class="d-flex">
                            <i class="bx bx-phone fs-5"></i>
                            <a class="mx-3" href="https://t.me/+3oS0jI5nqCo0NDAx" target="_blank" style="margin-top: -2px;">+1 2243520536</a><br><br>
                        </div>
                    </div>
                </div>
                <div class="about mt-2 border-radius box-shadow">
                    <div class="p-3">
                        <h6 class="title">Advertisements</h6>
                        <div class="table-responsive ads-table">
                            <table class="table table-borderless table-striped table-sm font-size-12 text-start  ">
                                <thead class="bg-light">
                                    <tr >
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th style="width: 20%;">View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="data,index in this.data_advertisement.list" :key="index">
                                        <td>{{data.name}}</td>
                                        <td>{{data.description}}</td>
                                        <td>
                                            <div v-if="parsedMedia(data.media).length > 0">
                                                <img @click="getAdsData(data),image_modal = true" height="30" width="30"  class="cursor-pointer" v-for="(image, index) in parsedMedia(data.media)" :src="getMediaUrl(image)" :key="index" :alt="`Image ${index + 1}`"/>
                                            </div>
                                            <div v-else>
                                                <img @click="getAdsData(data),image_modal = true" height="30" width="30" class="cursor-pointer"  src="@/assets/images/no-image.png">
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="px-3 pb-3">
                        <button class="see-all p-2" @click="all_ads_modal = true">See all</button>
                    </div>
                </div>
                <div class="about mt-2 border-radius box-shadow">
                    <div class="p-3">
                        <h6 class="title">Charity</h6>
                        <p class="description p-1 px-2">Every charitable act is a stepping stone toward heaven.</p>
                        <img src="@/assets/images/charity.jpg" style="height:300px;width: 100%;">
                    </div>
                    <div class="px-3 pb-3">
                        <button class="see-all p-2" @click="tabs = 'charity'">See all</button>
                    </div>
                </div>
                <div class="about mt-2 border-radius box-shadow">
                    <div class="p-3">
                        <h6 class="title">Recent Media</h6>
                        <!-- <span v-for="data,index in this.recent_media" :key="index">
                            <img class="recent-image" v-if="data.length > 0" :src="`${image_api}/${data}`"/>
                        </span> -->
                        <img v-if="this.recent_media[0]" class="recent-image" :src="`${image_api}/${this.recent_media[0]}`"/>
                        <img v-if="this.recent_media[1]" class="recent-image" :src="`${image_api}/${this.recent_media[1]}`"/>
                        <img v-if="this.recent_media[2]" class="recent-image" :src="`${image_api}/${this.recent_media[2]}`"/>
                        <img v-if="this.recent_media[3]" class="recent-image" :src="`${image_api}/${this.recent_media[3]}`"/>
                        <!-- <div>
                            <img class="cursor-pointer" v-for="(image, index) in this.recent_media" :src="getMediaUrl(image)" :key="index"/>
                        </div> -->

                    </div>
                    <div class="px-3 pb-3">
                        <button class="see-all p-2" @click="see_all_recent_media = true">See all</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="body" v-if="tabs == 'announcement'">
            <div class="">
                <div class="mt-2 bg-white border-radius box-shadow" v-for="data,i in this.data_announcement.list" :key="i">
                    <div class="d-flex p-3" style="position:relative">
                        <div class="users" >
                            <img
                                height="40" width="40"
                                class="raidus-100"
                                src="@/assets/images/profiles/default.png" >
                        </div>
                        <div>
                            <span class="mx-3" style="color: #050505">Admin</span>
                            <p class="mx-3" style=" color:#999999; font-size: 10px;">{{`${timeOnly(data.created_at)} ~ ${dateOnly(data.created_at)}`}}</p>
                            <h6 class="mx-3" v-if="data.description != 'null'">{{ data.description}}</h6>
                            <div v-if="data.media">
                                <div class="image-container" v-if="parsedMedia(data.media).length > 0" >
                                    <img @click="postView(image)" v-for="(image, index) in parsedMedia(data.media)" :src="getMediaUrl(image)" :key="index" :alt="`Image ${index + 1}`" class="media-image cursor-pointer" height="250" width="250"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="body" v-if="tabs == 'media'">
            <div class="w-100">
                <div class="row mt-4">
                    <div
                        v-for="data in this.recent_media"
                        :key="data.id"
                        class="col-xl-4 col-sm-6"
                    >
                        <div  class="box-shadow">
                            <img @click="postView(data)" class="see-all-media card p-2 cursor-pointer" v-if="data.length > 0" :src="`${image_api}/${data}`"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="body" v-if="tabs == 'charity'">
            <div class="charity mt-2 border-radius box-shadow" v-if="random_charity">
                <div class="p-3">
                    <h6 class="title">Charity</h6>
                    <p class="description text-muted p-1 px-2">{{random_charity.description}}</p>
                    <div v-if="random_charity.media">
                        <img :src="`${image_api}/${random_charity.media[0]}`" style="height:300px;width: 100%;">
                    </div>
                </div>
            </div>
        </div>
        <div class="body row" v-if="tabs == 'rules'">
            <div class="col-12">
                <div class="rules-card mt-3 box-shadow" style="border-radius:5px;">
                    <h5 class="px-3 pt-3 pb-2">Group Rules</h5>
                    <div class="px-4 pb-4">
                        <div class="mb-2">
                            <label class="fw-bolder">1. Be Respectful</label>
                            <div class="text-wrap text-muted">
                                Treat All Members with Kindness and respect. We embrace diversity and encourage constructive discussions.
                            </div>
                        </div>
                        <div class="mb-2">
                            <label class="fw-bolder">2. Keep it Civil</label>
                            <div class="text-wrap text-muted">
                                Disagreements are natural, but keep debates civil and refrain from personal attacks, harassment, or offensive language
                            </div>
                        </div>
                        <div class="mb-2">
                            <label class="fw-bolder">3. No Hate Speech or Discrimination</label>
                            <div class="text-wrap text-muted">
                                Hate Speech, discrimination, or any form of bigotry will not be tolerated. This includes but is not limited to racism, sexism, homophobia, and transphobia.
                            </div>
                        </div>
                        <div class="mb-2">
                            <label class="fw-bolder">4. Serious Inquiry Only</label>
                            <div class="text-wrap text-muted">
                                Please only comment or express interest if you are genuinely interested in purchasing the item.
                            </div>
                        </div>
                        <div class="mb-2">
                            <label class="fw-bolder">5. First Come, First Served</label>
                            <div class="text-wrap text-muted">
                                Items are sold on a first-come, first-served basis. The first buyer to comment “Sold” “Mine” or express clear interest has priority.
                            </div>
                        </div>
                        <div class="mb-2">
                            <label class="fw-bolder">6. No Negotiating in Comments</label>
                            <div class="text-wrap text-muted">
                                Negotiations should be conducted via private message (PM) to the seller.Avoid negotiating prices publicly in the comments section.
                            </div>
                        </div>
                        <div class="mb-2">
                            <label class="fw-bolder">7. Payment Timeless</label>
                            <div class="text-wrap text-muted">
                                Once a sale is agreed upon, prompt payment is appreciated. Please finalise payment within the agreed-upon timeframe to secure the item.
                            </div>
                        </div>
                        <div class="mb-2">
                            <label class="fw-bolder">8. Payment Methods</label>
                            <div class="text-wrap text-muted">
                                Sellers may specify accepted payment methods. Ensure you’re comfortable with the payment method before committing to a purchase.
                            </div>
                        </div>
                        <div class="mb-2">
                            <label class="fw-bolder">9. Shipping Details</label>
                            <div class="text-wrap text-muted">
                                Shipping Costs, Methods, and delivery times should be clearly communicated by the seller, Buyers are responsible for providing accurate shipping information.
                            </div>
                        </div>
                        <div class="mb-2">
                            <label class="fw-bolder">10. Communication is the Key</label>
                            <div class="text-wrap text-muted">
                                Maintain Open communication with the seller throughout the transaction process. If you have any questions or concerns.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="rules-card mt-3 box-shadow" style="border-radius:5px;">
                    <h5 class="px-3 pt-3 pb-2">Seller's Rule</h5>
                    <div class="px-4 pb-4">
                        <div class="mb-2">
                            <div class="text-wrap mb-1 text-muted">
                                1. You can only post 5 times per day can be mixed of photos and videos.
                            </div>
                            <div class="text-wrap mb-1 text-muted">
                                2. You can Post unlimited Featured in your Sellers Profile Account.
                            </div>
                            <div class="text-wrap mb-1 text-muted">
                                3. Strictly No Posting of your Detailed Mode of Payments.
                            </div>
                            <div class="text-wrap mb-1 text-muted">
                                4. No Posting of your Personal Infos such as social media accounts, phone numbers, email address etc.
                            </div>
                            <div class="text-wrap mb-1 text-muted">
                                5. No watermark in photos or videos
                            </div>
                            <div class="text-wrap mb-1 text-muted">
                                6. Do not insert/attached any invoices inside the package of your buyer.(you will encounter that most of buyers are also drop shippers)
                            </div>
                            <div class="text-wrap mb-1 text-muted">
                                7. You can message your buyer directly about transactions except phishing to move outside our portal. In this case, admin will automatically kick you out , or paused your account.
                            </div>
                            <div class="text-wrap mb-1 text-muted">
                                8. As a seller , you are responsible to give a detailed informations that the buyers need, you are required to discuss your seller’s policy for payments and shipping details.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12" style="margin-bottom:200px;">
                <div class="rules-card mt-3 box-shadow" style="border-radius:5px;">
                    <h5 class="px-3 pt-3 pb-2">Buyer's Rule</h5>
                    <div class="px-4 pb-4">
                        <div class="mb-2">
                            <div class="text-wrap mb-1 text-muted">
                                1. Take a screenshot of your selected photo and kindly direct message the seller.
                            </div>
                            <div class="text-wrap mb-1 text-muted">
                                2. Do not post or claim an item if your unsure with your purchase, we will definitely highlights those who will play your role.
                            </div>
                            <div class="text-wrap mb-1 text-muted">
                                3. Confirm the Seller’s policy about payments/shippings etc.
                            </div>
                            <div class="text-wrap mb-1 text-muted">
                                4. Strictly No Posting of your Detailed Mode of Payments.
                            </div>
                            <div class="text-wrap mb-1 text-muted">
                                5. Be Nice and respect our Sellers.
                            </div>
                            <div class="text-wrap mb-1 text-muted">
                                6. If something inappropriate occurred , Don’t Hesitate to contact our admin.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="body" v-if="tabs == 'fortune'">
            <div class="col-12">
                <div class="fortune-card mt-3 box-shadow" style="border-radius:5px;">
                    <div class="p-3">
                        <label class="fw-bold">Fortune</label>
                        <div class="text-wrap text-muted">
                            We also have available via online for Fortune Teller such as Astrology / Tarot Reader / Medium / Fengsui Practioner/ Clairvoyant : who have the ability to see things beyond normal sensory perception, ofter used to predict the future. Kindly Please contact our admin for any questions or Booking an appointments
                        </div>
                        <div class="text-center">
                            <a href="https://t.me/+dKf6JsnXIzNmMzAx" target="_blank" class="btn btn-info btn-sm">Request</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="body" v-if="tabs == 'about'">
            <div class="col-12">
                <div class="bg-white mt-2 border-radius box-shadow">
                    <div class="p-3">
                        <!-- <h6 class="fw-bold" style="color: #050505">About us</h6> -->
                        <span class="fw-bolder">About us</span>
                        <p class="p-1 px-2" style="font-size: 14px;">This portal is design for all the sellers who wants to do business in secured pace. <br><br> This portal is only for Inspired Items.<br><br>For Long time purposes of Business<br><br>This portal will also affiliated with charities - pay in any amount/anytime<br><br>Our Portal is also affiliated with a very strong healer/Fortune Teller/ Fengsui practitioner, anyone willing to talk or ask for a guidance , please don’t hesitate to contact our admin.</p>
                        <div class="d-flex">
                            <i class="bx bx-money fs-5"></i>
                            <span class="mx-3" style="margin-top: -2px;">Selling Products</span><br><br>
                        </div>
                        <!-- <div class="d-flex">
                            <i class="bx bx-dollar fs-5"></i>
                            <span class="mx-3" style="margin-top: -2px;">Buying</span><br><br>
                        </div> -->
                        <div class="d-flex">
                            <i class="bx bx-phone fs-5"></i>
                            <a class="mx-3" href="https://t.me/+3oS0jI5nqCo0NDAx" target="_blank" style="margin-top: -2px;">+1 2243520536</a><br><br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- MODALS -->
        <b-modal 
            v-model="post_modal" 
            no-close-on-backdrop
            hide-footer
            size="md"
            title="Create Post" 
            centered
            >
            <div class="d-flex">
                <div class="users">
                    <div class="">
                        <img v-if="!this.profile.image" 
                            height="40" width="40"
                            class="raidus-100"
                            src="@/assets/images/profiles/default.png" >
                        <img
                            v-if="this.profile.image"
                            :src="`${image_api}/${this.profile.image}`"
                            height="40" width="40"
                            class="raidus-100"
                        />
                    </div>
                </div>
                <div class="mx-3">
                    <span style="color: #050505">{{profile.username}}</span>
                    <p style=" color:#999999; font-size: 14px;">Public</p>
                </div>
            </div>
            <textarea class="form-control mb-3 write-something-input" placeholder="Write something..." v-model="pl_post.text">
            </textarea>
            <input
                type="file"
                ref="uploadFile"
                multiple 
                class="form-control mb-3" 
                @change="handleFileSelect" accept="image/*,video/*"
                />
            <div class="text-end mt-3">
                <b-button class="mx-1 w-100" variant="info" @click="addPost()">Post</b-button>
            </div>
        </b-modal>
        <b-modal 
            v-model="update_post_modal" 
            no-close-on-backdrop
            hide-footer
            size="md"
            title="Update Post" 
            centered
            >
            <div class="d-flex">
                <div class="users">
                    <div class="">
                        <img v-if="!this.profile.image" 
                            height="40" width="40"
                            class="raidus-100"
                            src="@/assets/images/profiles/default.png" >
                        <img
                            v-if="this.profile.image"
                            :src="`${image_api}/${this.profile.image}`"
                            height="40" width="40"
                            class="raidus-100"
                        />
                    </div>
                </div>
                <div class="mx-3">
                    <span style="color: #050505">{{profile.username}}</span>
                    <p style=" color:#999999; font-size: 14px;">Public</p>
                </div>
            </div>
            <textarea class="form-control mb-3 write-something-input" placeholder="Write something..." v-model="pl_post_update.text">
            </textarea>
            <input
                type="file"
                ref="uploadFile"
                multiple 
                class="form-control mb-3" 
                @change="handleFileSelectUpdate" accept="image/*,video/*"
                />
            <div class="text-end mt-3">
                <b-button class="mx-1 w-100" variant="info" @click="updatePost()">Post</b-button>
            </div>
        </b-modal>
        <b-modal 
            v-model="search_modal" 
            no-close-on-backdrop
            hide-footer
            size="md"
            title="Search" 
            centered
            >
            <div class="d-flex mb-3">
                <div class="search w-100">
                    <i class="bx bx-search"></i>
                    <input
                        type="text"
                        placeholder="Search this group"
                        class="form-control"
                    />
                </div>
            </div>
            <h5>Recent Searches</h5>
            <div>
                <div class="d-flex p-3 recent-searches-button" v-for="data,i in 5" :key="i">
                    <i class="bx bx-time-five fs-4"></i>
                    <span class="mx-2">Bags</span>
                </div>
            </div>
        </b-modal>
        <b-modal 
            v-model="share_modal" 
            no-close-on-backdrop
            hide-footer
            size="md"
            title="Share" 
            centered
            >
            <h5>Copy Link</h5>
            <input class="form-control mb-3" value="kio88.com/id=Dq12eyv4asdq6772"/>
        </b-modal>
        <b-modal 
            v-model="invite_modal" 
            no-close-on-backdrop
            hide-footer
            size="md"
            title="Invite" 
            centered
            >
            <h5>Link</h5>
            <input class="form-control mb-3" disabled/>
        </b-modal>
        <b-modal 
            v-model="image_modal" 
            no-close-on-backdrop
            hide-footer
            size="lg"
            title="Image" 
            centered
            >
            <div v-if="parsedMedia(this.get_ads_data.media).length > 0">
                <img height="500" style="width: 100%;" v-for="(image, index) in parsedMedia(this.get_ads_data.media)" :src="getMediaUrl(image)" :key="index" :alt="`Image ${index + 1}`"/>
            </div>
            <div v-else>
                <img height="500" style="width: 100%;" src="@/assets/images/no-image.png">
            </div>
            
        </b-modal>
        <b-modal 
            v-model="all_ads_modal" 
            no-close-on-backdrop
            hide-footer
            size="lg"
            title="Advertisements" 
            centered
            >
            <div id="carouselExampleCaption" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner" role="listbox">
                    <div :class="index == 0 ? 'carousel-item active' : 'carousel-item'" v-for="data,index in this.data_advertisement.list" :key="index">
                        <div v-if="parsedMedia(data.media).length > 0">
                            <img height="500" style="width: 100%;" v-for="(image, index) in parsedMedia(data.media)" :src="getMediaUrl(image)" :key="index" :alt="`Image ${index + 1}`"/>
                        </div>
                        <div v-else>
                            <img height="500" style="width: 100%;" src="@/assets/images/no-image.png">
                        </div>
                        <div class="carousel-caption d-none d-md-block" style="background-color: #000000b4; border-radius: 15px;">
                            <h5 class="text-white">{{data.name }}</h5>
                            <p class="text-white">{{data.description}}</p>
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleCaption" role="button"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleCaption" role="button"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </b-modal>
        <b-modal 
            v-model="see_all_recent_media" 
            no-close-on-backdrop
            hide-footer
            size="lg"
            title="Gallery" 
            centered
            >
            <div class="row recent-media">
                <div
                    v-for="data in this.recent_media"
                    :key="data.id"
                    class="col-xl-4 col-sm-6"
                >
                    <div  class="card p-1 box-shadow">
                        <img class="see-all-media card p-2" v-if="data.length > 0" :src="`${image_api}/${data}`"/>
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal 
            v-model="view_all_modal" 
            no-close-on-backdrop
            hide-footer
            size="lg"
            title="View All" 
            centered
            >
            <div
                v-for="data,index in this.view_all_media"
                :key="index"
            >
                <div  class="box-shadow view-images">
                    <img class="p-1" v-if="data.length > 0" :src="`${image_api}/${data}`" />
                </div>
            </div>
        </b-modal>
        <b-modal 
            v-model="post_view_modal" 
            hide-footer
            size="md"
            title="View" 
            centered
            hide-header
            >
            <img class="post-view-image image-pointer" :src="`${image_api}/${this.post_view_media}`"/>
        </b-modal>
    </div>
</template>
<script>
import navBar from "@/components/nav-bar.vue";
import '@/assets/css/user-dashboard.css';
import { mapActions,mapGetters } from "vuex";
import formatter from '@/mixins/formatter';
import Swal from 'sweetalert2';
import Echo from "laravel-echo";
import { clothsData } from "../ecommerce/data-products";
import Loader from '@/components/widgets/loader.vue';
export default {
    data() {
        return {
            clothsData: clothsData,
            members: '4.2k',
			data: {
				list: [
                    {
                        comments:[],
                    }
                ],
                reactions:[],
                media:[],
				links: [],
				current_page: 1,
				per_page: 1,
				last_page: 1,
			},
			data_announcement: {
				list: [],
                media:[],
				links: [],
				current_page: 1,
				per_page: 1,
				last_page: 1,
			},
			data_advertisement: {
				list: [],
                media:[],
				links: [],
				current_page: 1,
				per_page: 1,
				last_page: 1,
			},
			data_charity: {
				list: [],
                media:[],
				links: [],
				current_page: 1,
				per_page: 1,
				last_page: 1,
			},
            random_charity:{},
            pl_post:{
                text: null,
                reaction: null,
            },
            pl_post_update:{
                text: null,
                reaction: null,
                id: '',
            },
            showAllMedia: false,
            content: '',
            post_modal: false,
            search_modal: false,
            share_modal: false,
            invite_modal: false,
            image_modal: false,
            all_ads_modal: false,
            see_all_recent_media: false,
            post_view_modal: false,
            update_post_modal:false,
            update_post_data: null,
            slide: 0,
            sliding: null,
            image_api: process.env.VUE_APP_BACKEND_API_IMAGES,
            mediaInput: null,
            selectedMedia: [],
            selectedUpdateMedia: [],
            mediaJSON: null,
            comment: null,
            reply_index: null,
            comment_input: null,
            reply_toggle:false,
            reply_owner: "",
            recent_media:[],
            post_id:null,
            comment_id:null,
            get_ads_data: [],
            post_view_media: [],
            reactionsVisible: null,
            reaction_id: null,
            toggle: false,
            loading: false,
            reactions: ['👍','😆','❤️', '😮'],
            tabs: 'available',
            view_all_media: [],
            view_all_modal: false,
            max_size_post: false,
        };
    },
    mixins:[formatter],
	components: {
		navBar,Loader
	},
	computed: {
        ...mapGetters('auth', ['profile']),
	},
    methods:{
		...mapActions("transaction", {
			iList: "getList",
		}),
		...mapActions("posts", {
			createPost: "createPosts",
			getList: "getPosts",
			reactionPost: "postReaction",
			editPost: "postEdit",
			removePost: "postRemove",
		}),
		...mapActions("comment", {
			addComment: "createComment",
			deleteComment: "removeComment",
			getComment: "getComments",
			addReplies: "addReply",
		}),
		...mapActions("ban", {
			userBan: "banUser",
		}),
		...mapActions("notifications", {
			getNotifications: "notificationList",
		}),
		...mapActions("messages", {
			getThreads: "getList",
			storeThread: "newThread",
			checkerThread: "checkThread",
			messageUser: "message",
			showUserThread: "showThread",
		}),
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        async initList(p) {
			var pl = {
				page: p,
                sort: 'created_at',
                order: 'desc',
                limit: 50
			};
            this.loading = true
			const data = await this.getList(pl);
            this.loading = false
			this.data.list = data.data;
			this.data.list.comments = data.data.comments;
			this.data.reactions = data.data.reactions;
			this.data.links = data.links;
			this.data.current_page = data.current_page;
			this.data.per_page = data.per_page;
			this.data.last_page = data.last_page;
            const arr = data.data;
            var storage = [];
            arr.forEach(element => {
                if(element.media){
                    storage.push(element.media)
                }
            });
            let parsedData = Object.values(storage).map(item => JSON.parse(item));
            this.recent_media = parsedData;
			this.webSocket();
		},
        async initListAnnouncement(p) {
			var pl = {
				page: p,
                type: 'announcement'
			};
			const data = await this.getNotifications(pl);
			this.data_announcement.list = data.data;
			this.data_announcement.links = data.links;
			this.data_announcement.current_page = data.current_page;
			this.data_announcement.per_page = data.per_page;
			this.data_announcement.last_page = data.last_page;
		},
        async initListCharity(p) {
			var pl = {
				page: p,
                type: 'charity'
			};
			const data = await this.getNotifications(pl);
			this.data_charity.list = data.data;
			this.data_charity.links = data.links;
			this.data_charity.current_page = data.current_page;
			this.data_charity.per_page = data.per_page;
			this.data_charity.last_page = data.last_page;
            this.shuffleCharity()
		},
        async initListAdverisements(p) {
			var pl = {
				page: p,
                type: 'advertisement'
			};
			const data = await this.getNotifications(pl);
			this.data_advertisement.list = data.data;
			this.data_advertisement.links = data.links;
			this.data_advertisement.current_page = data.current_page;
			this.data_advertisement.per_page = data.per_page;
			this.data_advertisement.last_page = data.last_page;
		},
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        },
        getAdsData(data){
            this.get_ads_data = data;
        },
        // RESPONSE MEDIA
        parsedMedia(media) {
            try {
                return JSON.parse(media);
            } catch (error) {
                return [];
            }
        },
        getMediaUrl(filename) {
            return `${this.image_api}/${filename}`;
        },
        // PROFILE IMAGE
        getImagePath(index) {
            return require(`@/assets/images/profiles/${index}.jpg`);
        },
        handleFileSelect(event) {
            var media_storage = 0;
            this.selectedMedia = Array.from(event.target.files);
            this.selectedMedia.forEach(element => {
                media_storage += element.size
                if(media_storage > 50000000){
                    this.max_size_post = true;
                }
            });
        },
        handleFileSelectUpdate(event) {
            this.selectedUpdateMedia = Array.from(event.target.files);
        },
        getPostData(data){
            this.update_post_modal = true;
            this.pl_post_update.id = data._id
        },
        formatText(text) {
            if(text){
                return text.replace(/\r/g, '<br>');
            }
        },
        async addPost(){
            const fd = new FormData();
            this.selectedMedia.forEach((file, index) => {
                fd.append(`media[${index}]`, file);
            });
            fd.append('text', this.pl_post.text);
            //  const media = this.selectedMedia.map((file, index) => ({
            //     [`media[${index}]`]: file
            // }));
            // var pl ={
            //     media: media,
            //     text: this.pl_post.text
            // }
            if(this.max_size_post == true){
                this.loading = false;
                this.post_modal = false;
                this.max_size_post = false;
                Swal.fire({
                    html: `You've reached the max file size limit (100mb)`,
                    type: "error",
                    icon: "error",
                    padding: "2em",
                });
            }else{
                this.loading = true;
                this.post_modal = false;
                const res = await this.createPost(fd);
                if (res.status == 200|| res.status == 'success') {
                    // Swal.fire({
                    //     title: "Success",
                    //     html: `Posted.`,
                    //     type: "success",
                    //     icon: "success",
                    //     padding: "2em",
                    // });
                    this.initList(1);
                    this.post_modal = false;
                    this.loading = false;
                }else{
                    this.initList(1);
                    Swal.fire({
                        html: res,
                        type: "error",
                        icon: "error",
                        padding: "2em",
                    });
                }
            }
        },
        async updatePost(){
            const fd = new FormData();
            this.selectedUpdateMedia.forEach((file, index) => {
                fd.append(`media[${index}]`, file);
            });
            fd.append('text', this.pl_post_update.text);
            fd.append('id', this.pl_post_update.id);
            var pl = {
                formdata: fd,
                id: this.pl_post_update.id
            }
            this.update_post_modal = false;
            this.loading = true;
            const res = await this.editPost(pl);
            this.loading = false;
            if (res.status == 200|| res.status == 'success') {
                this.initList(1);
            }
        },
        async deletePost(data){
            var pl = {
                id: data._id,
            }
            const confirmed = await Swal.fire({
                title: 'Are you sure you want to DELETE this?',
                html: `<table class="table table-bordered table-sm font-size-12 text-start">
                            <tbody>
                                <tr>
                                    <th>Description</th>
                                    <td>${data.text}</td>
                                </tr>
                            </tbody>
                        </table>`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#74788d',
            }).then((result) => {
                return result.value;
            });
            if (confirmed) {
                const res = await this.removePost(pl);
                if (res.status == 200|| res.status == 'success') {
                    Swal.fire({
                        title: "Success",
                        html: `Deletion Successful.`,
                        type: "success",
                        icon: "success",
                        padding: "2em",
                    });
                    this.initList(1);
                }else{
                    Swal.fire({
                        html: res,
                        type: "error",
                        icon: "error",
                        padding: "2em",
                    });
                }
            }
        },
        async postReaction(data,event){
            var pl = {
                post_id: data._id,
            }
            if(event.target.innerHTML == '👍'){
                pl['type'] = 'like'
            }
            if(event.target.innerHTML == '😆'){
                pl['type'] = 'haha'
            }
            if(event.target.innerHTML == '❤️'){
                pl['type'] = 'heart'
            }
            if(event.target.innerHTML == '😮'){
                pl['type'] = 'wow'
            }
           await this.reactionPost(pl);
           this.reactionsVisible = null
        },
        async commentSubmit(event,data){
            const fd = new FormData();
            fd.append('text', event.target.value);
            fd.append('post_id', data._id);
            this.post_id =data._id;
            const success = await this.addComment(fd);
            if(success.status == 200 || success.status == 'success'){
                this.initList(1);
            }
            event.target.value = ''
        },
        submitComment(data, i) {
            const commentInput = this.$refs['commentInput' + i][0];
            const event = { target: commentInput };
            this.commentSubmit(event, data, i);
        },
        async replySubmit(event,data,index){
            this.comment_input = index
            const fd = new FormData();
            fd.append('text', event.target.value);
            fd.append('comment_id', data._id);
            this.comment_id = data._id
            const success = await this.addReplies(fd);
            if(success.status == 200 || success.status == 'success'){
                this.initList(1);
            }
            event.target.value = ''
        },
        replyComment(data, index) {
            const replyInput = this.$refs['replyInput' + index][0];
            const event = { target: replyInput };
            this.replySubmit(event, data, index);
        },
        async commentDelete(data){
            var pl = {
                id: data._id,
            }
            const confirmed = await Swal.fire({
                title: 'Are you sure you want to DELETE this?',
                html: `<table class="table table-bordered table-sm font-size-12 text-start">
                            <tbody>
                                <tr>
                                    <th>Description</th>
                                    <td>${data.text}</td>
                                </tr>
                            </tbody>
                        </table>`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#74788d',
            }).then((result) => {
                return result.value;
            });
            if (confirmed) {
                const res = await this.deleteComment(pl);
                if (res.status == 200|| res.status == 'success') {
                    Swal.fire({
                        title: "Success",
                        html: `Deletion Successful.`,
                        type: "success",
                        icon: "success",
                        padding: "2em",
                    });
                    this.initList(1);
                }else{
                    Swal.fire({
                        html: res,
                        type: "error",
                        icon: "error",
                        padding: "2em",
                    });
                }
            }
        },
        getIndex(index){
            this.reply_index = index;
            if(this.reply_toggle == false){
                this.reply_toggle = true;
            }else{
                this.reply_toggle = false;
            }
        },
        getReplyData(data,index){
            this.reply_owner = `${data.owner}: `
            this.reply_index = index;
            if(this.reply_toggle == false){
                this.reply_toggle = true;
            }else{
                this.reply_toggle = false;
                this.reply_owner = ""
            }
        },
        async sendTo(data){
            // const fd = new FormData();
            // fd.append('receiver_id', data.owner_id);
            // await this.storeThread(fd);
            // this.$router.push({
            //     path:"/messages",
            //     query: {
			// 		id: data.owner_id,
			// 		name: data.owner,
			// 	},
            // })
            var pl = {
                receiver_id : data.owner_id
            }
            const success = await this.checkerThread(pl)
            if(success.status == 200 || success.status == 'success'){
                this.$router.push({
                    path:"/messages",
                    query: {
                        id: data.owner_id,
                        name: data.owner,
                    },
                })
            }else{
                const fd = new FormData();
                fd.append('receiver_id', data.owner_id);
                await this.storeThread(fd);
                this.$router.push({
                    path:"/messages",
                    query: {
                        id: data.owner_id,
                        name: data.owner,
                    },
                })
            }
        },
        gotoProfile(data){  
            if(this.profile.id == data.owner_id){
                this.$router.push({path: "/profile"});
            }else if(this.profile.id != data.owner_id){
                this.$router.push({
                    path:"/profile",
                    query: {
                        user_id: data.owner_id,
                        name: data.owner,
                    }
                })
            }
        },
        discussions(){
            this.$router.push({path: "/"});
        },
        async reportUser(data){
            var pl = {
                id: data.owner_id
            }
            const res = await this.userBan(pl);
            if (res.status == 200|| res.status == 'success') {
                Swal.fire({
                    title: "Success",
                    html: `You successfully reported this user.`,
                    type: "success",
                    icon: "success",
                    padding: "2em",
                });
                this.initList(1);
            }else{
                Swal.fire({
                    html: res,
                    type: "error",
                    icon: "error",
                    padding: "2em",
                });
            }
        },
        toggleReactions(i,data) {
            this.reactionsVisible = i;
            this.reaction_id = data._id;
            if(this.toggle == false){
                this.toggle = true
            }else{
                this.toggle = false;
            }
        },
        postView(data){
            this.post_view_modal = true;
            this.post_view_media = data;
        },
        viewImages(data){
            this.view_all_modal = true;
            this.view_all_media =  JSON.parse(data.media);
        },
        shuffleCharity() {
            this.random_charity = this.data_charity.list[Math.floor(Math.random() * this.data_charity.list.length)]
            if(this.random_charity){
                this.random_charity.media = JSON.parse(this.random_charity.media)
            }
        },
        webSocket() {
			window.Pusher = require("pusher-js");
			window.Echo = new Echo({
				broadcaster: "pusher",
				key: process.env.VUE_APP_PUSHER_APP_KEY,
				// wsHost: process.env.VUE_APP_PUSHER_HOST,
				wsPort: process.env.VUE_APP_PUSHER_PORT,
				disableStats: true,
				cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
				enabledTransports: ["ws", "wss"],
			});
			window.Echo.channel(`new-notification`).listen(
				"NewNotificationEvent",
				async (e) => {
                    const cindex = this.data.list.findIndex(i => i._id == this.post_id);
                    const react_index = this.data.list.findIndex(i => i._id == this.reaction_id);
                    // const sindex = this.data.list[cindex].comments.findIndex(i => i._id == this.reply_index);
                    // console.log(this.data.list[cindex].comments[this.comment_input])
                    if(e.type == 'like' || e.type == 'haha' || e.type == 'heart' || e.type == 'wow'){
                        var pl_like = {
                            reactions: e.reactions,
                            text: e.text,
                            media: e.media,
                            owner_id: e.owner_id,
                            owner: e.owner,
                            created_at: e.created_at,
                            comments: []
                        };
                        this.post_modal = false;
                        await this.data.list[react_index].reactions.push(pl_like);
                    }
                    if(e.type == 'post' ){
                        var pl = {
                            reactions: e.reactions,
                            text: e.text,
                            media: e.media,
                            owner_id: e.owner_id,
                            owner: e.owner,
                            created_at: e.created_at,
                            comments: []
                        };
                        this.post_modal = false;
                        await this.data.list.unshift(pl);
                    }
                    if(e.type == 'comment'){
                        var pl_comment = {
                            text: e.text,
                            media: e.media,
                            owner_id: e.owner_id,
                            owner: e.owner,
                            created_at: e.created_at,
                            owner_dp: e.owner_dp,
                            sub_comments: []
                        };
                        this.data.list.filter(element => {
                            if(element._id == this.post_id){
                                this.data.list[cindex].comments.push(pl_comment);
                            }
                        });
                    }
                    if(e.type == 'subComment'){
                        // var pl_subcomment = {
                        //     text: e.text,
                        //     media: e.media,
                        //     owner_id: e.owner_id,
                        //     owner: e.owner,
                        //     created_at: e.created_at,
                        //     owner_dp: e.owner_dp,
                        // };
                        // this.data.list[cindex].comments.forEach(element => {
                        //     element.comments.forEach(e => {
                        //         if(e._id == this.comment_id){
                        //             console.log(e._id)
                        //             console.log(this.comment_id)
                        //         }
                        //     });
                        // });
                        // console.log(e)
                    }
				}
			);
		},
    },
    mounted(){
        this.scrollToTop();
        this.initList(1);
        this.initListAdverisements(1);
        this.initListAnnouncement(1);
        this.initListCharity(1);
        if(this.$route.query.id){
            console.log(this.$route.query.id)
            setTimeout(() => {
            this.scrollToElement(this.$route.query.id);
                
            }, 2000);
        }
    }
};
</script>
<style scoped>
.reaction-button-container {
  position: relative;
  display: inline-block;
}

.reactions {
  display: flex;
  position: absolute;
  top: -70px;
  left: -30px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease;
}

.reaction {
  font-size: 24px;
  padding: 5px;
  margin: 0 2px;
  cursor: pointer;
  transition: transform 0.2s;
}

.reaction:hover {
  transform: scale(1.5);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>